<template>
	<div>
		<b-dropdown class="align-self-center action-menu p-0" variant="link" dropup right no-caret>
			<template v-slot:button-content>
				<div>
					<div class="material-icons-outlined" style="font-size: 1.25rem;">more_vert</div>
				</div>
			</template>
			<b-dropdown-header>
				<div class="mb-2">
					<div class="text-muted">Size:</div>
					~{{styleSize | prettyBytes}}
				</div>
				<div class="mb-2">
					<div class="text-muted">Lines:</div>
					{{styleLines}}
				</div>
				<div class="mb-2">
					<div class="text-muted">Created:</div>
					{{ this.item.createdAt | moment("MMMM Do, YYYY") }}
				</div>
				<div>
					<div class="text-muted">Last Modified:</div>
					{{ this.item.updatedAt | moment("MMMM Do, YYYY") }}
				</div>
			</b-dropdown-header>
			<template v-if="editable">
				<b-dropdown-divider></b-dropdown-divider>
				<b-dropdown-item @click.prevent="remove()" class="font-weight-bold">
					<div class="d-flex align-items-center">
						<div class="material-icons-outlined mr-2">delete</div>
						<div>Delete</div>
					</div>
				</b-dropdown-item>
			</template>
		</b-dropdown>
	</div>
</template>

<script>
export default {
	name: 'StyleMenu',
	props: [
		'item',
		'draft',
		'editable'
	],
	data () {
		return {
		}
	},
	computed: {
		styleSize () {
			return this.item.val.length;
		},
		styleLines () {
			return this.item.val.split(/\r\n|\r|\n/).length;
		},
	},
	methods: {
		deleteStyle (style) {
			if (this.draft) {
				style.draft = false;
				style.save();
				this.makeToast('Draft deleted');
			} else {
				style.remove();
				this.makeToast('Stylesheet deleted');
			}
		},
		toggleFavorite (style) {
			style.isFavorite = !style.isFavorite;
			style.patch();
			if (style.isFavorite) {
				this.makeToast('Added to favorites');
			} else {
				this.makeToast('Removed from favorites');
			}
		},
		remove() {
			this.$bvModal.msgBoxConfirm(
				'Are you sure you want to delete this stylesheet? This is a permanent action.', 
				this.confirmModalOpts
			).then(confirm => {
				if (confirm) {
					this.deleteStyle(this.item);
				}
			});
        }
	}
}
</script>

<style lang="scss">
.action-menu {
	.btn {
		height: auto !important;
		padding: 0;
		line-height: 1;
	}
}
</style>
